<template>
  <div class="step-title">
    <span class="step">{{ step + 1 }}</span> {{ stepTitle }}
  </div>
  <div class="content">
    <div class="detail">
      <div class="info" v-for="(item, index) in content" :key="index">
        <span>{{ item.title }}</span>
        <copy-board :data-value="item.value"
          ><span>{{ item.value }}</span></copy-board
        >
      </div>
    </div>
    <div class="btn-copy copyBtn">
      点击信息可复制
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { RECEIVING_BANK } from '@/consts/index'

export default defineComponent({
  props: {
    step: {
      type: Number,
      default: 0
    },
    stepTitle: {
      type: String,
      default: '转入港币'
    },
    market: {
      type: String,
      default: 'hk'
    }
  },
  setup (props) {
    const content = reactive([
      {
        title: props.step ? '美元收款账号' : '港币收款账号',
        value: RECEIVING_BANK[props.step]?.benefitNo
      },
      { title: '收款银行中文名称', value: RECEIVING_BANK[props.step]?.benefitBank },
      { title: '收款银行英文名称', value: RECEIVING_BANK[props.step]?.bankEName },
      { title: '收款账户中文名称', value: RECEIVING_BANK[props.step]?.accountCName },
      { title: '收款账户英文名称', value: RECEIVING_BANK[props.step]?.accountEName },
      { title: 'SWIFT-BIC', value: RECEIVING_BANK[props.step]?.swiftCode },
      { title: '银行编号（BANK CODE）', value: RECEIVING_BANK[props.step]?.depositBankId },
      { title: '分行编号', value: RECEIVING_BANK[props.step]?.branchCode }
    ])
    return {
      content
    }
  }
})
</script>

<style lang="scss" scoped>
.step-title {
  font-size: 16px;
  margin: 30px 0;
}
.step {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #ffb400;
  border-radius: 50%;
  color: #0f1423;
}
.content {
  position: relative;
  height: 320px;
  background: #19213a;
  border-radius: 8px;
  color: #bfc7de;
  .detail {
    padding: 20px;
    .info {
      @include flex-box();
      margin-bottom: 15px;
    }
  }
  .btn-copy {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #2a3148;
    border-radius: 4px;
    text-align: center;
  }
}
</style>
